import { useQuery } from '@tanstack/react-query'
import { GET_PROPOSAL_DETAIL } from '../constants'
import { type IProposalDetailParams } from '../services/types'
import { getProposalDetail } from '../services/api'

interface IUseProposalDetail {
  params: IProposalDetailParams
}

export function useProposalDetail({ params }: IUseProposalDetail) {
  return useQuery({
    queryKey: [GET_PROPOSAL_DETAIL.concat(params.token), params],
    queryFn: async () => await getProposalDetail(params),
  })
}
