import api from 'api'
import { GET_PROPOSAL_DETAIL } from '../constants'
import { type IProposalDetailParams } from './types'

export const getProposalDetail = async ({
  token,
  ...params
}: IProposalDetailParams) => {
  return await api
    .get(GET_PROPOSAL_DETAIL.concat(token), { params })
    .then(({ data }) => data)
}
