import * as ProposalAction from 'store/actions/index'
import { useDispatch, useSelector } from 'react-redux'
import { GET_PROPOSAL_DETAIL } from 'domains/proposal/detail/constants'
import { useProposalDetail } from 'domains/proposal/detail/hooks/useProposalDetail'
import { queryClient } from 'services/queryClient'
import { useEffect } from 'react'

export function useUpdateProposalRedux() {
  const dispatch = useDispatch()

  const token = useSelector((state) => state?.Reducer1?.proposal?.token)

  const { data } = useProposalDetail({
    params: { token },
  })

  useEffect(() => {
    if (data) {
      dispatch(ProposalAction.SaveProposalRedux(data.proposal))

      data.quotes.forEach((quote) => {
        dispatch(ProposalAction.UpdateQuoteRedux(quote))
      })

      data.sizings.forEach((sizing) => {
        dispatch(ProposalAction.UpdateSizingRedux(sizing))
      })
    }
  }, [data, dispatch])

  function refetchProposalDetail() {
    queryClient.invalidateQueries(GET_PROPOSAL_DETAIL.concat(token))
  }

  return {
    refetchProposalDetail,
  }
}
